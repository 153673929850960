'use client';

import { publicApiUrl } from '@sortlist-frontend/data-fetching/api';
import { captureException } from '@sortlist-frontend/mlm/standalone';
import { DeepMaybe, useIsVisible } from '@sortlist-frontend/utils';
import { MutableRefObject, Ref, useEffect, useRef } from 'react';

import { useMergedFeatureFlagConfig } from './FeatureFlagsProvider';
import { trackExperiment, TrackExperimentValues } from './tracking';
import {
  AvailableFeatureNames,
  AvailableFeatureToggles,
  Experiment,
  FeatureFlagValue,
  FeatureToggleConfig,
  isFeatureFlagWithExperiment,
  isFeatureFlagWithVariants,
} from './types';

type Options =
  | { triggerEvent: undefined; viewEventRef: undefined }
  | {
      viewEventRef: Ref<HTMLElement>;
      triggerEvent: true;
    };

export function useIsFeatureActive<
  T extends AvailableFeatureNames,
  F extends AvailableFeatureToggles[T],
  V extends DeepMaybe<F, ['variants', number]>,
  E extends DeepMaybe<F, ['experiment']> & Experiment,
>(
  featureName: T,
  options?: Options,
): {
  /**
   * boolean: true or false if the feature is enabled in any variant excluding control
   */
  isActive: boolean;
  /**
   * string: returns the currently activated variant including control
   */
  variant?: V;
  /**
   * string: returns the currently given flag, provided for type safety on the consumer side
   */
  flag: T;
  /**
   * string: currently active experiment if it is running. The value is extracted from the cookies
   */
  experiment?: E;
  /**
   * function: can be called to manually trigger an experiment viewed event, to be used
   * instead of the default beaviour via options
   */
  triggerEvent: VoidFunction;
} {
  const featureFlagConfig = useMergedFeatureFlagConfig();
  const currentConfig = featureFlagConfig[featureName];

  // A feature is considered active if it's either true, or something other than
  // control is enabled (AB test). All controls end with -c according to conventions
  const isActive = isValueActive(currentConfig.defaultValue);
  const variant = getVariant(currentConfig);
  const experiment = getExperiment(currentConfig);

  // Block below is reserved for the experiment view tracking
  const hasSeenExperiment = useRef(false);
  const shouldTriggerOnView = options?.triggerEvent === true;
  const withElement = options?.viewEventRef != null;
  const isElementVisible = useIsVisible({ ref: options?.viewEventRef as MutableRefObject<HTMLElement> });

  const handleExperimentViewed = async () => {
    const data: TrackExperimentValues = {
      eventType: 'experimentViewed',
      gbuuid: experiment!.assignedUniqueId!,
      url: window.location.href,
      experiments: {
        [experiment!.key]: variant as string,
      },
      endpointBaseUrl: publicApiUrl!,
      context: 'client',
    };
    trackExperiment(data, {
      onError: (error, message) => {
        captureException(`[Tracking error]: experimentViewed failed in useIsFeatureActive hook`, {
          extra: { error, data, currentConfig, message },
        });
      },
    });
  };

  const handleTriggerEvent = () => {
    if (experiment != null && experiment.isRunning) {
      handleExperimentViewed();
    }
  };

  useEffect(() => {
    if (hasSeenExperiment.current) return;
    if (shouldTriggerOnView && experiment != null && experiment.isRunning) {
      if (withElement && !isElementVisible) return;
      hasSeenExperiment.current = true;

      handleExperimentViewed();
    }
  }, [shouldTriggerOnView, isElementVisible, experiment?.key]);

  return {
    isActive,
    variant: variant as V,
    experiment: experiment as E | undefined,
    flag: featureName,
    triggerEvent: handleTriggerEvent,
  };
}

function getExperiment(config: FeatureToggleConfig) {
  if (isFeatureFlagWithExperiment(config)) {
    return config.experiment.isRunning ? config.experiment : undefined;
  }
}

function getVariant(config: FeatureToggleConfig) {
  const value = config.defaultValue;
  if (typeof value === 'string' && isFeatureFlagWithVariants(config)) {
    return value as keyof typeof config.variants;
  }
}

function isValueActive(v: FeatureFlagValue | boolean) {
  if (typeof v === 'string') {
    return !v.endsWith('-c') && v !== 'control';
  }
  return v;
}
